
































































































import { Component, Vue } from 'vue-property-decorator';
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
} from '@/interfaces';
import { dispatchGetUsers, dispatchCreateUser } from '@/store/admin/actions';

@Component
export default class CreateUser extends Vue {
  public valid = false;
  public fullName: string = '';
  public email: string = '';
  public isActive: boolean = true;
  public isSuperuser: boolean = false;
  public setPassword = false;
  public password1: string = '';
  public password2: string = '';
  public codigoPropietario: string = '';
  public codigoInquilino: string = '';
  public data() {
    return {
      rules: {
        required: (value) => !!value || 'Obligaratorio.',
        counter: (value) => value.length <= 6 || 'Max 6 caracteres',
      },
    };
  }
  public async mounted() {
    await dispatchGetUsers(this.$store);
    this.reset();
  }

  public reset() {
    this.password1 = '';
    this.password2 = '';
    this.fullName = '';
    this.email = '';
    this.isActive = true;
    this.isSuperuser = false;
    this.codigoPropietario = '';
    this.codigoInquilino = '';
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IUserProfileCreate = {
        email: this.email,
      };
      if (this.fullName) {
        updatedProfile.nombre = this.fullName;
      }
      if (this.email) {
        updatedProfile.email = this.email;
      }
      if (this.codigoPropietario) {
        updatedProfile.codigo_propietario = this.codigoPropietario;
      }
      if (this.codigoInquilino) {
        updatedProfile.codigo_inquilino = this.codigoInquilino;
      }
      updatedProfile.is_active = this.isActive;
      updatedProfile.is_super = this.isSuperuser;
      updatedProfile.password = this.password1;
      await dispatchCreateUser(this.$store, updatedProfile);
      this.$router.push('/main/admin/users');
    }
  }
}
